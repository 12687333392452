<template>
  <div
      class="filter-panel-container">
    <component
        :is="renderComponent"
        v-bind="$props"
        @filterChanges="updateFilters" />
  </div>
</template>

<script>
import HorizontalFilterPanel from "./variants/HorizontalFilterPanel";
import VerticalFilterPanel from "./variants/VerticalFilterPanel";
import { camelCase, startCase } from 'lodash';

export default {
  name: "ImageBase",
  components: {
    HorizontalFilterPanel,
    VerticalFilterPanel
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    filters: {
      type: Array,
      required: true
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'VerticalFilterPanel';
      }
      return startCase(camelCase(`${this.variant}FilterPanel`)).replace(/ /g, '');
    }
  },
  methods: {
    updateFilters(val){
      this.$emit('filterChanges', val)
    }
  }
};
</script>