import routes from './routes';
import store from './store';
import AdminView from './views/AdminView';

const AdminModule = {
  path: 'admin',
  name: 'Admin',
  enabled: true,
  store: store,
  children: routes,
  component: AdminView,
};

export default AdminModule;
