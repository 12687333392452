import Vue from 'vue';
import Vuex from 'vuex';
import LZString from 'lz-string';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    settings: {
      compressLocalStorage: false
    }
  },
  mutations: {},
  actions: {
    updateLocalStorage({ state }, payload) {
      if (state.settings.compressLocalStorage) {
        localStorage.setItem(
          payload,
          LZString.compressToUTF16(JSON.stringify(state[payload]))
        );
      } else {
        localStorage.setItem(payload, JSON.stringify(state[payload]));
      }
    },
    localStorageToStore({ state, commit }, payload) {
      if (localStorage.getItem(payload)) {
        let item = JSON.parse(localStorage.getItem(payload));
        if (state.settings.compressLocalStorage) {
          item = JSON.parse(
            LZString.decompressFromUTF16(localStorage.getItem(payload))
          );
        }
        const newPayload = { field: payload, value: item };
        commit('UPDATE_FROM_LOCAL_STORAGE', newPayload);
      }
    }
  },
  modules: {}
});
