<template>
  <div class="keyword-search-filter-container">
    <el-input
        v-model="keyword"
        class="search-input"
        type="search">
      <i
          slot="prefix"
          class="el-icon-search el-input__icon" />
    </el-input>
  </div>
</template>

<script>
export default {
  name: "KeywordSearchFilter",
  data() {
    return {
      keyword: ""
    }
  },
  watch: {
    keyword(val) {
      this.$emit('filterChanges', val)
    }
  }
};
</script>

<style lang="scss" scoped>
.keyword-search-filter-container {
  margin: 0 var(--margin-m);

  ::v-deep .search-input {
    input {
      border-radius: var(--border-radius-xxl);
      padding: var(--padding-l);
      text-indent: 30px;
      border-color: var(--color-secondary-background);
    }
  }
}
</style>