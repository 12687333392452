const productDetailsViewMetaMixin = {
  metaInfo() {
    return {
      title: `${this.$metaData.pdp.prefixTitle}: ${this.PageTitle} ${this.$metaData.pdp.suffixTitle}`,
      meta: [
        {
          name: 'description',
          content: this.description
        },
        {
          property: 'og:type',
          content: "product"
        },
        {
          property: 'og:title',
          content: `${this.$metaData.pdp.prefixTitle}: ${this.PageTitle} ${this.$metaData.pdp.suffixTitle}`
        },
        {
          property: 'og:title',
          content: `${this.$metaData.pdp.prefixTitle}: ${this.PageTitle} ${this.$metaData.pdp.suffixTitle}`
        },
        {
          name: 'og:description',
          content: this.description
        },
        {
          name: 'article:publisher',
          content: this.$metaData.article.publisher
        },
      ]
    }
  },
}

export default productDetailsViewMetaMixin