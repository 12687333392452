<template>
  <div class="top-navigation-base-container">
    <template v-if="items">
      <ul class="top-navigation">
        <li
            v-for="(item, index) in items"
            :key="`nav-item-${index}`">
          <TopNavigationItem
              :item="item" />
        </li>
      </ul>
    </template>
    <div class="shopping-cart-container">
      <ShoppingCartBase />
    </div>
  </div>
</template>

<script>
import TopNavigationItem from "./TopNavigationItem";
export default {
  name: 'TopNavigation',
  components: {
    TopNavigationItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "TopNavigation";
</style>
