<template>
  <div class="default-product-list-item-container">
    <div class="product-container__outer">
      <div class="product-container__inner">
        <div class="image-container">
          <CoreImageBase
              :image-src="ImageSrc"
              alt="`Image of ${Title}`" />
        </div>
        <div class="description-container">
          <div
              v-if="Category"
              class="product-category">
            <span>{{ Category }}</span>
          </div>
          <div class="product-title">
            <h1>{{ Title }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="product-footer">
      <div class="stock-status-wrapper">
        <StockStatus :stock-status="WarehouseQuantity" />
      </div>
      <div
          class="item-price"
          :class="{ special: IsSpecial }">
        <ShopProductPrice
            :special="IsSpecial"
            :sell-price="DefaultPrice"
            :rrp-price="RRPPrice" />
      </div>
    </div>

    <div class="product-options">
      <router-link
          :to="ProductDetailsLink"
          class="primary">
        Show Details
      </router-link>

      <AddToCartBase
          :item="item"
          :stock-status="WarehouseQuantity" />
    </div>
  </div>
</template>

<script>

import slugify from "slugify"
import StockStatus from "@/Modules/Shop/components/StockStatus/StockStatus";
import AddToCartBase from "@/Modules/Shop/components/AddToCart/AddToCartBase";

export default {
  name: "DefaultProduct",
  components: { AddToCartBase, StockStatus },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ProductUrl() {
      return slugify(`${this.item.Name}-pid${this.item.ID}`)
    },
    ProductDetailsLink(){
      return `/product/${this.ProductUrl}`
    },
    ImageSrc() {
      if (this.item.Images?.length > 0) {
        return this.item.Images[0]?.ThumbURL
      }
      return "https://via.placeholder.com/150"
    },
    Title() {
      return this.item.Name
    },
    Category() {
      if (this.item.Categories?.length > 0) {
        return this.item.Categories[0].Category[0].CategoryName
      }

      return false
    },
    WarehouseQuantity() {
      if (this.item.WarehouseQuantity) {
        return this.item.WarehouseQuantity
      }
      return {}
    },
    IsSpecial() {
      return this.item.DefaultPrice < parseFloat(this.item.RRPPRice)
    },
    DefaultPrice() {
      if (this.item.DefaultPrice) {
        return this.item.DefaultPrice
      }
      return 'N/A'
    },
    RRPPrice() {
      if (this.item.RRPPrice) {
        if (!isNaN(this.DefaultPrice) && this.DefaultPrice < this.item.RRPPrice) {
          return this.item.RRPPrice
        }
      }
      return null
    }
  },
  methods: {
    addToCart() {
      const cartItem = {
        id: parseInt(this.item.ID),
        title: this.Title,
        quantity: 1
      }

      this.$store.dispatch('Shop/addToShoppingCart', cartItem)
      this.$notify({
        dangerouslyUseHTMLString: true,
        title: 'Product added to cart',
        message: `<span class="product-name-tag">${this.Title}</span> has been added to your shopping cart`,
        position: 'bottom-right'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'DefaultProductListItem';

</style>