<template>
  <div class="products-container target">
    <header>
      <span>
        CaneToad Equip Catalog
      </span>
      <div
          class="delete-container"
          :class="{ dragenter : deleteItem.itemId }"
          @dragover="handleDragOverDelete($event)">
        <i
            class="el-icon-delete" />
      </div>
    </header>
    <div class="product-list-body">
      <ul class="main-categories">
        <li
            v-for="(mainCategory, index) in categories"
            :key="`main-category-${index}`"
            :class="{ dragenter : dragOverItem === mainCategory.id }">
          <div class="title">{{ mainCategory.title }}</div>

          <!-- Display products -->
          <ul class="products">
            <li
                v-for="(product, productIndex) in getDroppedProducts(mainCategory.id)"
                :key="`dropped-product-${productIndex}`"
                class="product-item small">
              <div class="image-container">
                <template
                    v-if="product.Images.length">
                  <img
                      :src="product.Images[0].ThumbURL"
                      :alt="product.Name">
                </template>
              </div>
              <div class="product-name-container">
                {{ product.Name }} <small>{{ product.SKU }}</small>
              </div>
            </li>
          </ul>

          <ul
              v-if="mainCategory.children"
              class="sub-categories">
            <li
                v-for="(subCategory, subIndex) in mainCategory.children"
                :key="`sub-category-${subIndex}`"
                :class="[{ block: subCategory.children.length },{ dragenter : dragOverItem === subCategory.id } ]"
                @drop="onDrop($event, subCategory.id)"
                @dragover="handleDragOver($event, subCategory.id)"
                @dragover.prevent
                @dragenter.prevent>
              <div class="title">{{ subCategory.title }}</div>

              <!-- Display products -->
              <ul class="products">
                <li
                    v-for="(product, productIndex) in getDroppedProducts(subCategory.id)"
                    :key="`dropped-product-${productIndex}`"
                    draggable="true"
                    class="product-item small"
                    @dragstart="startDrag($event, subCategory.id, product)"
                    @dragend="handleDragEnd">
                  <div class="image-container">
                    <template
                        v-if="product.Images.length">
                      <img
                          :src="product.Images[0].ThumbURL"
                          :alt="product.Name">
                    </template>
                  </div>
                  <div class="product-name-container">
                    {{ product.Name }} <small>{{ product.SKU }}</small>
                  </div>
                </li>
              </ul>

              <ul
                  v-if="subCategory.children"
                  class="sub-sub-categories">
                <li
                    v-for="(subSubCategory, subSubIndex) in subCategory.children"
                    :key="`sub-category-${subSubIndex}`"
                    :class="{ dragenter : dragOverItem === subSubCategory.id }"
                    @drop="onDrop($event, subSubCategory.id)"
                    @dragover="handleDragOver($event, subSubCategory.id)"
                    @dragenter.prevent>
                  <div class="title">{{ subSubCategory.title }} ({{ subSubCategory.id }})</div>

                  <!-- Display sub sub category products -->
                  <ul class="products">
                    <li
                        v-for="(product, productIndex) in getDroppedProducts(subSubCategory.id)"
                        :key="`dropped-product-${productIndex}`"
                        class="product-item small"
                        draggable="true"
                        @dragstart="startDrag($event, subSubCategory.id, product)"
                        @dragend="handleDragEnd">
                      <div class="image-container">
                        <template
                            v-if="product.Images.length">
                          <img
                              :src="product.Images[0].ThumbURL"
                              :alt="product.Name">
                        </template>
                      </div>
                      <div class="product-name-container">
                        {{ product.Name }} <small>{{ product.SKU }}</small>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


export default {
  name: "ProductCategorizationTarget",
  props: {
    products: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    newProductList: {
      type: Array,
      required: true
    },
    dragOverItem: {
      required: true
    }
  },
  data() {
    return {
      moveItem: {},
      deleteItem: {}
    }
  },
  methods: {
    getDroppedProducts(index) {
      return this.newProductList.filter(product => product.CategoryIds.includes(index))
    },
    onDrop (evt, targetCategoryId) {
      evt.stopPropagation()
      if (this.moveItem.sourceCategoryId) {
      } else {
        const itemID = evt.dataTransfer.getData('itemID')

        const item = this.products.find(item => item.ID === itemID)

        const foundAssignedProducts = this.newProductList.find(product => {
          if (product.ID === itemID) {
            if (!product.CategoryIds.includes(targetCategoryId)) {
              product.CategoryIds.push(targetCategoryId)
            }
            return product
          }});

        if (!foundAssignedProducts) {
          item.CategoryIds = [targetCategoryId]
          this.newProductList.push(item)
        }
      }
    },
    startDrag (evt, sourceCategoryId, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.ID)

      this.moveItem = {
        itemId: item.ID,
        sourceCategoryId: sourceCategoryId,
        targetCategoryId: null
      }
    },
    handleDragEnd(event){
      event.preventDefault()
      if (this.deleteItem.itemId) {
        this.$emit("deleteItem",
          {
            itemId: this.deleteItem.itemId,
            sourceCategoryId: this.deleteItem.sourceCategoryId
          }
        )
        this.deleteItem = {}
        this.moveItem = {}
      }
      else if (this.moveItem.itemId) {
        this.$emit("moveItem",
          {
            itemId: this.moveItem.itemId,
            sourceCategoryId: this.moveItem.sourceCategoryId,
            targetCategoryId: this.moveItem.targetCategoryId
          }
        )
        this.moveItem = {}
      } else {
        this.$emit("endDragging")
      }
    },
    handleDragOver(event, categoryId) {
      event.preventDefault();
      event.stopPropagation();
      this.moveItem.targetCategoryId = categoryId
      this.$emit("updateDragOver", categoryId)
    },
    handleDragOverDelete(event) {
      event.stopPropagation();
      this.deleteItem = this.moveItem
    }
  }
};
</script>

<style lang="scss" scoped>
@import "ProductCategorizationTarget";
</style>