<template>
  <div class="dashboard-container">
    <h1>Welcome back</h1>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  padding: var(--padding-m);

  h1 {
    font-weight: 400;
    color: var(--color-default-grey);
    font-size: var(--font-size-xl);
  }
  section {
    padding-top: var(--padding-xxl);
  }
}
</style>
