<template>
  <div class="sidebar-category-list">
    <template v-if="items.length > 0">
      <CategoryListItemBase
          v-for="(item, index) in items"
          :key="`category-list-item-${index}`"
          :variant="categoryListItemVariant"
          :item="item" />
    </template>
  </div>
</template>

<script>
import CategoryListItemBase from "@/Modules/Shop/components/CategoryListItem/CategoryListItemBase";
export default {
  name: "SidebarCategoryList",
  components: { CategoryListItemBase },
  props: {
    items: {
      type: Array,
      required: true
    },
    categoryListItemVariant: {
      type: String,
      default: "default"
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-category-list {

}
</style>