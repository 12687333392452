<template>
  <img
      :src="imageSrc"
      :alt="alt">
</template>

<script>
export default {
  name: "DefaultImage",
  props: {
    imageSrc: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
}
</style>