<template>
  <div class="default-product-list">
    <template v-if="items === null">
      <div class="empty-message-container">
        <el-empty description="We couldn't find any products." />
      </div>
    </template>
    <template v-else-if="items.length">
      <ProductListItemBase
          v-for="(item, index) in computedItems"
          :key="`product-list-item-${index}`"
          :variant="productListItemVariant"
          :item="item" />
    </template>
  </div>
</template>

<script>
import ProductListItemBase from "@/Modules/Shop/components/ProductListItem/ProductListItemBase";
export default {
  name: "DefaultProductList",
  components: { ProductListItemBase },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    limit: {
      type: [Number, Boolean],
      default: false
    },
    productListItemVariant: {
      type: String,
      default: "default"
    }
  },
  computed: {
    computedItems() {
      let items = this.items
      if (this.limit) {
        items = this.items.slice(0,this.limit)
      }
      return items
    }
  }
};
</script>

<style lang="scss" scoped>
.default-product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .empty-message-container {
    background: white;
    border-radius: var(--border-radius-m);
    padding: var(--padding-m);
  }
}
</style>