<template>
  <div class="admin-view-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AdminView"
};
</script>

<style scoped>

</style>