<template>
  <div
      v-if="imageSrc"
      class="image-container">
    <component
        :is="renderComponent"
        v-bind="$props" />
  </div>
</template>

<script>
import DefaultImage from "./variants/DefaultImage";
import { camelCase, startCase } from 'lodash';

export default {
  name: "ImageBase",
  components: {
    DefaultImage,
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    imageSrc: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: null
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultImage';
      }
      return startCase(camelCase(`${this.variant}Image`)).replace(/ /g, '');
    }
  }
};
</script>