<template>
  <div class="default-navigation-container">
    Default Navigation
  </div>
</template>

<script>
export default {
  name: "DefaultNavigation"
};
</script>

<style scoped></style>
