import CategoriesView from "./views/CategoriesView";
import ProductCategorizationView from "./views/ProductCategorizationView";
const routes = [
  {
    path: '/admin/categories',
    name: 'admin-categories-view',
    component: CategoriesView,
  },
  {
    path: '/product-categorisation',
    name: 'product-categorisation',
    component: ProductCategorizationView,
  },
];

export default routes;
