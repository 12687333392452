<template>
  <div class="product-price-container">
    <div
        v-if="rrpPrice && IsSpecial"
        class="rrp-price-container"
        :class="[{ special: IsSpecial }, {'mark-discount': markDiscount}]">
      <span class="currency-symbol">{{ currencySymbol }}</span>
      <span class="rrp-price">{{ ComputedRRP }}</span>
    </div>

    <div
        v-if="sellPrice"
        class="sell-price-container"
        :class="[{ special: IsSpecial }, {'mark-discount': markDiscount}]">
      <span class="currency-symbol">{{ currencySymbol }}</span>
      <span class="sell-price">{{ ComputedSellPrice }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductPrice",
  props: {
    sellPrice: {
      type: [Number, String],
      default: null
    },
    rrpPrice: {
      type: [Number, String],
      default: null
    },
    special: {
      type: Boolean,
      default: false
    },
    markDiscount: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      currencySymbol: "A$"
    }
  },
  computed: {
    ComputedRRP() {
      if (this.rrpPrice) {
        return new Intl.NumberFormat('en-AU', { minimumFractionDigits: 2 }).format(this.rrpPrice)
      }
      return null
    },
    ComputedSellPrice() {
      if (this.sellPrice) {
        return new Intl.NumberFormat('en-AU', { minimumFractionDigits: 2 }).format(this.sellPrice)
      }
      return null
    },
    IsSpecial() {
      return this.special && parseFloat(this.ComputedSellPrice) < parseFloat(this.ComputedRRP)
    },
  }
};
</script>

<style lang="scss" scoped>
.product-price-container {
  text-align: right;
  .rrp-price-container {
    font-family: Raleway, sans-serif;
    position: relative;
    display: inline-block;

    .currency-symbol {
      font-size: var(--font-size-xs);
      font-weight: 600;
      color: rgba(0,0,0,0.7);
      margin-right: 2px;
    }
    .rrp-price {
      font-size: var(--font-size-m);
      font-weight: 600;
      line-height: 1;
    }

    &.special {
      .rrp-price {
        color: #FFF;
      }

      .currency-symbol {
        color: var(--color-special-price-currency-text);
      }
    }

    &.mark-discount {

      :after {
        content: "";
        position: absolute;
        top: 4px;
        right: -1px;
        width: 80%;
        border-top: 1px solid #d7432d;
        transform: rotate(-17deg) scale(0.9);
        transform-origin: top right;
      }
    }
  }

  .sell-price-container {
    font-family: Raleway, sans-serif;
    position: relative;

    &.special {
      .sell-price {
        color: var(--color-primary);
      }

      .currency-symbol {
        color: var(--color-special-price-currency-text);
      }
    }

    .currency-symbol {
      font-size: var(--font-size-s);
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 2px;
    }

    .sell-price {
      font-size: var(--font-size-xl);
      font-weight: 600;
      color: var(--color-price-text);
    }
  }
}
</style>