<template>
  <div class="homepage-category-list-container">
    <div class="category-tiles-container">
      <template v-if="items.length > 0">
        <ShopCategoryListItemBase
            v-for="(item, index) in items"
            :key="`category-list-item-${index}`"
            variant="homepage"
            :item="item" />
      </template>  
    </div>
    <div class="category-cta-container">
      <div class="category-cta-content">
        <h3>Almost ready to camp but need a few more things?</h3>
        <p>
          Discover our <span class="inline-highlight">Camping</span> and <span class="inline-highlight">Outdoor gear</span> and check out
          what we have for you in our <span class="inline-highlight">Offroad Parts </span>section.
        </p>
      </div>
      <div class="category-cta-image">
        <img
            src="@/assets/img/camping_scene.png"
            alt="Camping">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageCategoryList",
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'HomepageCategoryList';
</style>