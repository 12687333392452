<template>
  <div class="default-call-to-action-container">
    Default Call To Action
  </div>
</template>

<script>
export default {
  name: "DefaultCallToAction"
};
</script>

<style scoped>

</style>