import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import VueMeta from 'vue-meta'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import locale from 'element-ui/lib/locale/lang/en'

// Theming
import '@/assets/theme/elementui-overwrites.scss';
import '@/assets/theme/canetoad-admin.theme.scss';

import CoreComponents from './Modules/Core';

// Refactor to use this later for registering child components
// const addModule = (Module) => {
//   if (Module.registerComponents) {
//     Module.registerComponents(Vue);
//   }
//
//   if (Module.store) {
//     store.registerModule(Module.name, Module.store);
//   }
//
//   if (Module.children && Module.registerChildren) {
//     Module.children.forEach(addModule);
//   }
// };

// Dynamically register child components
router.options.routes.forEach((route) => {
  if (route.children) {
    route.children.forEach((child) => {
      if (child.registerComponents) {
        child.registerComponents(Vue);
      }

      // Register store if module contains one
      if (child.store) {
        store.registerModule(child.name, child.store);
      }
    });
  }
});

// SEO Stuff
Vue.prototype.$metaData = {
  plp: {
    prefixTitle: "Best Offroad Aftermarket Parts in Brisbane",
    suffixTitle: "Offroad and Outdoor Equipment Warehouse Brisbane",
    prefixDescription: "",
    suffixDescription: "Browse through a wide range of great quality Offroad Aftermarket Parts"
  },
  pdp: {
    prefixTitle: "On Sale",
    suffixTitle: "Offroad and Outdoor Equipment Warehouse Brisbane",
    prefixDescription: "Discounted for a short period only.",
    suffixDescription: "Offroad and Outdoor Equipment Warehouse Brisbane"
  },
  article: {
    publisher: "https://www.facebook.com/canetoadequip/"
  }
}

Vue.use(ElementUI, { locale })
Vue.use(CoreComponents);
Vue.use(VueMeta)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
