<template>
  <div class="drawer-container">
    <header>
      <div class="title-container">
        <h3>{{ title }}</h3>
      </div>
      <div class="close-btn-container">
        <span
            class="close"
            @click="closeDrawer">X</span>
      </div>
    </header>
    <div class="content-container">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Drawer",
  props: {
    title: {
      type: String,
      default: null
    }
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer")
    }
  }
};
</script>

<style lang="scss" scoped>
.drawer-container {
  width: 100%;
  position: sticky;
  top: 0;
  box-shadow: var(--box-shadow-short);

  header {
    background: var(--color-secondary-background);
    padding: var(--padding-m);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-container {
      h3 {
        padding: 0;
        margin: 0;
        font-weight: 600;
        font-size: var(--font-size-xl);
        color: var(--color-primary-link);
      }
    }

    .close-btn-container {
      span.close {
        font-size: var(--font-size-m);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-dark-background);
        background: white;
        transition: var(--main-transition);
        cursor: pointer;

        &:hover {
          background: var(--color-secondary-background);

        }
      }
    }
  }

  .content-container {

  }
}
</style>