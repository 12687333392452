<template>
  <div
      class="filter-container">
    <component
        :is="renderComponent"
        :type="type"
        v-bind="filterProps"
        @filterChanges="updateFilters" />
  </div>
</template>

<script>
import KeywordSearchFilter from "./variants/KeywordSearchFilter";
import RangeFilter from "./variants/RangeFilter";
import CheckboxListFilter from "./variants/CheckboxListFilter";
import { camelCase, startCase } from 'lodash';

export default {
  name: "FilterBase",
  components: {
    KeywordSearchFilter,
    RangeFilter,
    CheckboxListFilter
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      required: true
    },
    filterProps: {
      type: Object,
      required: true
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultFilter';
      }
      return startCase(camelCase(`${this.variant}Filter`)).replace(/ /g, '');
    }
  },
  methods: {
    updateFilters(val){
      this.$emit("filterChanges", {type: this.type, values: val })
    }
  }
};
</script>