<template>
  <div class="call-to-action-base-container">
    <component
        :is="renderComponent"
        v-bind="$props" />
  </div>
</template>

<script>
import DefaultCallToAction from './variants/DefaultCallToAction';
import ProductSearchCallToAction from "./variants/ProductSearchCallToAction";
import NewsletterSignupCallToAction from "./variants/NewsletterSignupCallToAction";
import { camelCase, startCase } from 'lodash';

export default {
  name: "CallToActionBase",
  components: {
    DefaultCallToAction,
    ProductSearchCallToAction,
    NewsletterSignupCallToAction
  },
  props: {
    variant: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultCallToAction';
      }
      return startCase(camelCase(`${this.variant}CallToAction`)).replace(/ /g, '');
    }
  }
}
</script>

<style scoped>

</style>