<template>
  <div class="pagination-container">
    <div
        class="block"
        :class="blockClass">
      <el-pagination
          background
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          :layout="layout"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CorePagination",
  props: {
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100, 150 ]
    },
    pageSize: {
      type: Number,
      required: true
    },
    layout: {
      type: String,
      default: "total, sizes, pager, prev, next, jumper"
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      default: 1
    },
    blockClass: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("perPageChange", val)
    },
    handleCurrentChange(val) {
      this.$emit("currentPageChange", val)
    }
  }
}
</script>

<style lang="scss" scoped>
.block {

  &.bright {
    margin-top: var(--margin-m);
    background: rgba(255, 255, 255, 0.5);
    padding: var(--padding-m);
    border-radius: var(--border-radius-m);
  }
}
</style>