<template>
  <div class="newsletter-signup-call-to-action-container">
    <div class="content-container">
      <h1 v-if="title">
        {{ title }}
      </h1>
      <h2 v-if="subtitle">
        {{ subtitle }}
      </h2>
      <div class="email-form">
        <el-input
            v-model="email"
            type="email"
            class="email-input"
            placeholder="john.doe@example.org" />
        <button
            class="primary email-signup"
            disabled>
          Sign up
        </button>
      </div>
    </div>
    <div class="shade-divider hidden-xs-only">
      <BackgroundShade fill="#B9E6D0" />
    </div>
    <div class="image-container" />
  </div>
</template>

<script>
import BackgroundShade from "../components/BackgroundShade";
export default {
  name: 'ProductSearchCallToAction',
  components: { BackgroundShade },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      email: '',
    };
  },

};
</script>

<style lang="scss" scoped>
@import "NewsletterSignupCalltoAction";
</style>
