<template>
  <div class="default-add-to-cart-container">
    <div class="add-to-cart-inner">
      <div
          v-if="showQuantitySelector"
          class="product-quantity">
        <el-input-number
            v-model="quantity"
            class="product-quantity-element"
            size="large"
            controls-position="right"
            :min="1"
            :max="100" />
      </div>
      <div class="add-to-cart-button">
        <button
            class="primary"
            :class="[{ 'out-of-stock': IsOutOfStock }, size]"
            @click="addToCart">
          <span
              class="el-icon-shopping-cart-1" />
          <template v-if="IsOutOfStock">
            Out of stock
          </template>
          <template v-else>
            Add to Cart
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultAddToCart",
  props: {
    item: {
      type: Object,
      required: true
    },
    showQuantitySelector: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "normal"
    },
    stockStatus: {
      type: [Object, Array],
      default: () => null
    }
  },
  data() {
    return {
      quantity: 1,
    }
  },
  computed: {
    IsOutOfStock() {
      return this?.stockStatus?.Quantity <= 0;
    }
  },
  methods: {
    addToCart() {
      if (!this.IsOutOfStock) {
        const cartItem = {
          id: parseInt(this.item.ID),
          title: this.item.Name,
          quantity: this.quantity
        }

        this.$store.dispatch('Shop/addToShoppingCart', cartItem)

        this.$notify({
          dangerouslyUseHTMLString: true,
          title: 'Product added to cart',
          message: `<span class="product-name-tag">${this.item.Name}</span> <span class="inline-text"> added to your shopping cart</span>`,
          position: 'bottom-right',
          type: 'success',
          duration: 9999

        });

      } else {

        this.$notify({
          dangerouslyUseHTMLString: true,
          title: 'Product out of stock',
          message: `We're sorry but this product is currently out of stock.`,
          position: 'bottom-right',
          type: 'warning',
          duration: 9999999

        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.default-add-to-cart-container {
  .add-to-cart-inner {
    display: flex;
    gap: 20px;

    .add-to-cart-button {
      button {

        &.large {
          font-size: var(--font-size-xxl);
        }

        &.out-of-stock {
          background: black;
        }

      }
    }
  }

}
</style>