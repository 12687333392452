<template>
  <el-footer class="footer">
    <section class="link-list content limited-width-content">
      <div class="link-list-item">
        <p>Copyright 2022 - Nunki</p>
      </div>
    </section>
  </el-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #FFF;
  background-size: cover;
  padding: var(--padding-m);
  position: relative;

  .svg-container-1 {
    position: absolute;
    width: 100%;
    bottom: 230px;
    left: 0;
  }

  h3 {
    color: var(--color-primary);
    font-weight: 600;
  }

  p {
    font-size: var(--font-size-s);
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: white;
    font-size: var(--font-size-l);

    li {
      margin-bottom: var(--margin-s);
    }
  }

  .link-list {
    display: flex;
    gap: var(--margin-l);
    justify-content: space-between;

    .list-list-item {
      flex: 1;
    }
  }
}
</style>