<template>
  <div
      id="app">
    <div class="content-wrapper">
      <aside>
        <div class="logo-wrapper">
          <router-link to="/home">
            <img
                class="logo"
                alt="CaneToad Equip"
                src="./assets/img/logo.png">
          </router-link>
        </div>

        <div class="navigation-wrapper">
          <CoreNavigationBase
              :items="leftMenuItems"
              variant="left" />
        </div>
      </aside>
      <main>
        <router-view v-slot="{ Component, route }">
          <transition name="fade">
            <component
                :is="Component"
                :key="route.path" />
          </transition>
        </router-view>
      </main>
    </div>

    <CoreFooter />
  </div>
</template>

<script>
import { leftMenuItems } from './App';

export default {
  name: 'App',
  data() {
    return {
      leftMenuItems: leftMenuItems
    };
  },
  mounted() {
    document.addEventListener("securitypolicyviolation", (e) => {
      // eslint-disable-next-line no-console
      console.log("CSP Violation - Document URI:",e.documentURI);
      // eslint-disable-next-line no-console
      console.log("CSP Violation - Blocked URL:",e.blockedURI);
      // eslint-disable-next-line no-console
      console.log("CSP Violation - Directive:",e.violatedDirective);
      // eslint-disable-next-line no-console
      console.log("CSP Violation - Original Policy:",e.originalPolicy);
    });
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#app {
  transition: var(--main-transition);
  margin: var(--margin-xl);
  border-radius: var(--border-radius-m);
  box-shadow: var(--box-shadow-shortest);
  overflow: hidden;
  background: #f6f6f9;

  &.push {
    margin-left: -350px;
  }

  .content-wrapper {
    display: flex;
    justify-content: flex-start;
    position: relative;
    z-index: 2;

    main {
      flex: 3;
      transition: opacity 0.2s;
      &.opaque {
        opacity: .5;
      }
    }

    aside {
      min-width: 220px;
      max-width: 220px;
      flex: 1;
      background: #FFF;
      min-height: 100vh;
      border-right: 1px solid var(--color-light-grey);

      .drawer {
        background: white;
      }

      .logo-wrapper {
        text-align: center;
        padding: var(--padding-m);

        img {
          width: 100px;
          border: 1px solid var(--color-light-grey);
          padding: var(--padding-m);
          border-radius: var(--border-radius-s);
        }
      }

      .navigation-wrapper {
        margin-top: var(--margin-l);
      }
    }
  }
}

.el-header {
  padding: 20px 20px !important;
  box-sizing: border-box;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fcf6f3;

  &.header {
    display: flex;
    height: auto !important;
    gap: 40px;
    justify-content: space-between;
  }
  .logo {
    max-width: 150px;
    height: auto;
  }
  .navigation-wrapper {
    padding-top: 30px;
  }

  @media (max-width: 475px) {

    &.header {
      gap: 20px;
    }

    .logo {
      max-width: 100px;
    }

    .navigation-wrapper {
      padding-top: 0;
    }
  }
}


</style>
