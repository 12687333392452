<template>
  <div class="product-categorization-view-container">
    <header>
      <div class="actions-panel">
        <div class="headline">
          <h1>Import & categorize products</h1>
        </div>
        <div class="number-container">
          Allocated Products: {{ ProductAllocations.length }}
        </div>
        <div
            v-loading="importing"
            class="actions-container">
          <el-button
              type="primary"
              size="small"
              :disabled="importing || ProductAllocations.length === 0"
              @click="handleImportProducts">
            Save Category Changes
          </el-button>
        </div>
      </div>
    </header>

    <div class="product-lists-container">
      <div class="products-container source">
        <header>
          <div class="title">
            NETO Catalog
          </div>
          <div class="options">
            <el-checkbox v-model="unallocatedProductsOnly">
              Show unallocated only
            </el-checkbox>

            <el-checkbox v-model="showSearch">
              Show Search
            </el-checkbox>
          </div>
        </header>
        <div class="product-list-body">
          <div
              v-if="showSearch"
              class="product-search">
            <el-input
                v-model="searchKeyword"
                type="search" />
          </div>
          <ul
              class="product-list">
            <li
                v-for="(product, index) in Products"
                :key="`original-product-${index}`"
                class="product-item"
                draggable="true"
                @dragstart="startDrag($event, product)"
                @dragend="handleDragEnd">
              <div
                  class="count"
                  :class="{ active: countAllocations(product.ID) > 0}">
                {{ countAllocations(product.ID) }}
              </div>
              <div class="image-container">
                <template
                    v-if="product.Images.length">
                  <img
                      :src="product.Images[0].ThumbURL"
                      :alt="product.Name">
                </template>
              </div>
              <div class="product-name-container">
                {{ product.Name }} <small>{{ product.SKU }}</small>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <ProductCategorizationTarget
          :categories="SortedCategories"
          :products="Products"
          :drag-over-item="dragOverItem"
          :new-product-list="newProductList"
          @updateDragOver="handleUpdateDragOver"
          @moveItem="handleMoveItem"
          @deleteItem="handleDeleteItem"
          @endDragging="handleDragEnd" />
    </div>
  </div>
</template>
<script>
import ProductCategorizationTarget from "@/Modules/Admin/components/ProductCategorization/ProductCategorizationTarget";
export default {
  name: "ProductCategorizationView",
  components: {
    ProductCategorizationTarget
  },
  data() {
    return {
      unallocatedProductsOnly: false,
      showSearch: false,
      transferProducts: [],
      dragOverItem: null,
      newProductList: [],
      searchKeyword: "",
      importing: false
    }
  },
  computed: {
    Categories() {
      if (this.$store.state.Admin.categories?.data?.data) {
        return this.$store.state.Admin.categories.data.data
      }
      return []
    },
    MappedCategories() {
      return this.$store.state.Admin.categoryMapping
    },
    SortedCategories() {

      const { Categories } = this
      let mainCategories = Categories.filter(category => category.parentId === "0")

      mainCategories = mainCategories.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))


      mainCategories.forEach(mainCategory => {
        let subCategories = Categories.filter(subCategory => subCategory.parentId === mainCategory.id)
        subCategories = subCategories.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))

        mainCategory.children = subCategories.map(subCategory => {
          subCategory.children = Categories.filter(subSubCategory => subSubCategory.parentId === subCategory.id)
          return subCategory
        })
      })

      return mainCategories
    },
    GetProducts() {
      return this.$store.getters["Shop/filteredProducts"]({ page: 1, perPage: 500})
    },
    ProductAllocations() {
      const allocations = this.newProductList.map(product =>  (
        {
          productId: product.ID,
          categoryIds: product.CategoryIds
      }))
      return allocations
    },
    Products() {
      let products = this.GetProducts.products
      if (products) {
        if (this.unallocatedProductsOnly) {
          products = products.filter(product => !this.newProductList.find(newProduct => newProduct.ID === product.ID))
        }

        if (this.searchKeyword.length > 3 && this.showSearch) {
          const keyword = this.searchKeyword.toLowerCase();
          products = products.filter(product => product.Name.toLowerCase().includes(keyword) || product.SKU.toLowerCase().includes(keyword))
        }

        return products
      }
      return []
    }
  },
  async created() {
    await this.$store.dispatch("Shop/fetchProducts")
    await this.$store.dispatch("Admin/fetchCategories", { page: 1, perPage: 500});
    await this.loadCategoryMapping()
  },
  methods: {
    countAllocations(productId) {
      const foundProduct = this.newProductList.find(product => product.ID === productId)
      if (foundProduct) {
        return foundProduct.CategoryIds.length
      }
      return 0
    },
    getDroppedProducts(index) {
      return this.newProductList.filter(product => product.CategoryIds.includes(index))
    },
    startDrag (evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', item.ID)
    },
    onDrop (evt, targetCategoryId) {
      evt.stopPropagation()
      const itemID = evt.dataTransfer.getData('itemID')
      const item = this.Products.find(item => item.ID === itemID)

      const foundAssignedProducts = this.newProductList.find(product => {
        if (product.ID === itemID) {
          if (!product.CategoryIds.includes(targetCategoryId)) {
            product.CategoryIds.push(targetCategoryId)
          }
          return product
        }});

      if (!foundAssignedProducts) {
        item.CategoryIds = [targetCategoryId]
        this.newProductList.push(item)
      }
    },
    handleDragEnd(event){
      event.preventDefault()
      this.dragOverItem = null
    },
    handleUpdateDragOver(index) {
      this.dragOverItem = index
    },
    handleDragOver(event, index) {
      event.preventDefault();
      event.stopPropagation()
      this.dragOverItem = index
    },
    handleMoveItem(payload) {
      if (payload.itemId && payload.sourceCategoryId && payload.targetCategoryId) {
        const newProductList = this.newProductList.map(product => {
            if(product.ID === payload.itemId) {
              const categoryIds = product.CategoryIds

              const newCategoryIds = categoryIds.map(categoryId => {

                if (categoryId === payload.sourceCategoryId) {
                  categoryId = payload.targetCategoryId
                }
                return categoryId
              })
              product.CategoryIds = newCategoryIds
            }
            return product
          }
        )
        this.newProductList = newProductList
      }
    },
    handleDeleteItem(payload) {
      if (payload.itemId && payload.sourceCategoryId) {
        const newProductList = this.newProductList.map(product => {
            if(product.ID === payload.itemId) {
              const categoryIds = product.CategoryIds

              const newCategoryIds = categoryIds.map(categoryId => {

                if (categoryId !== payload.sourceCategoryId) {
                  return categoryId
                }
              })
              product.CategoryIds = newCategoryIds
            }
            return product
          }
        )
        this.newProductList = newProductList
      }
    },
    async handleImportProducts() {
      this.importing = true
      await this.$store.dispatch("Admin/importProducts", this.ProductAllocations)
      this.importing = false

      this.$notify({
        dangerouslyUseHTMLString: true,
        title: 'Products imported',
        message: `Your products have been successfully imported.`,
        position: 'bottom-right',
        type: 'success'

      });
    },
    async loadCategoryMapping() {
      this.importing = true
      await this.$store.dispatch("Admin/getCategoryMapping")

      const mutatedProductList = this.MappedCategories.map(mapping => {
        const product = this.Products.find(product => product.ID === mapping.productId)
        product.CategoryIds = mapping.categoryIds

        return product
      })
      this.newProductList = mutatedProductList
      this.importing = false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "ProductCategorizationView";
</style>