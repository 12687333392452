<template>
  <div class="product-details-view-container">
    <template v-if="Product">
      <section class="limited-width-content title">
        <div class="product-title-container">
          <h3>{{ Category }}</h3>
        </div>
      </section>

      <section class="product-main-container">
        <div class="section-inner flex-container limited-width-content">
          <div class="product-price-container">
            <div class="product-stock-status">
              <StockStatus :stock-status="WarehouseQuantity" />
            </div>

            <div class="product-title-container">
              <h1>{{ Product.Name }}</h1>
            </div>

            <ShopProductPrice
                :rrp-price="Product.RRPPrice"
                :sell-price="Product.DefaultPrice"
                :stock-status="WarehouseQuantity" />

            <ShopAddToCartBase
                size="large"
                :item="Product"
                :show-quantity-selector="true"
                :stock-status="WarehouseQuantity" />
          </div>

          <div class="product-image-container">
            <el-image
                class="product-image"
                fit="contain"
                :src="MainImageSource"
                :preview-src-list="PreviewSourceList">
              <div
                  slot="placeholder"
                  v-loading="true"
                  class="image-slot">
                Loading<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </div>
      </section>
      <section class="product-description-container limited-width-content">
        <div class="product-description-inner">
          <div class="product-description-header">
            <h4>Product Details > <span>{{ Product.Name }}</span></h4>
          </div>
          <!-- eslint-disable -->
          <div
              class="description-wrapper"
              v-html="SanitizedDescription" />
          <!-- eslint-enable -->
        </div>
      </section>
    </template>
    <template v-else>
      <div
          v-loading="true"
          class="loading-product" />
    </template>
  </div>
</template>

<script>
import StockStatus from "@/Modules/Shop/components/StockStatus/StockStatus";
import productDetailsViewMetaMixin from "@/Modules/Shop/views/ProductDetailsViewMetaData";

export default {
  name: "ProductDetailsView",
  components: { StockStatus },
  mixins: [productDetailsViewMetaMixin],
  computed: {
    MainImageSource() {
      let source = null

      if (this.Product.Images.length) {
        return this.Product.Images[0].URL
      }
      return source
    },
    PreviewSourceList() {
      let sourceList = []

      if (this.Product.Images.length) {
        sourceList = this.Product.Images.map(image => image.URL
        )
      }
      return sourceList
    },
    ProductSlug() {
      return this.$route.params.productTitle
    },
    ProductId() {
      return this.$store.getters["Shop/getProductIdFromSlug"](this.ProductSlug)
    },
    Product() {
      return this.$store.getters["Shop/getProductFromId"](this.ProductId)
    },
    PageTitle() {
      return this.Product?.Name
    },
    PageDescription() {
      return `${this.PageTitle}: ${this.Product?.Description}`
    },
    Category() {
      let categories = null
      if (this.Product.Categories?.length > 0) {
        categories = this.Product.Categories[0].Category.map(category => category.CategoryName)
      }
      categories = categories.join(" > ")
      return categories
    },
    WarehouseQuantity() {
      if (this.Product.WarehouseQuantity) {
        return this.Product.WarehouseQuantity
      }
      return {}
    },
    SanitizedDescription() {
      const regex = /(style=".+?")/gm;
      const subst = "";
      let result = this.Product.Description.replace(regex, subst);
      result = result.replace(/<\/?strong>/g, "");
      result = result.replace(/<\/?strong >/g, "");
      result = result.replace(/<\/?i>/g, "");
      result = result.replace(/<\/?i >/g, "");
      result = result.replace(/<\/?em >/g, "");
      result = result.replace(/<\/?em>/g, "");
      result = result.replace(/align="left"/g, "");
      result = result.replace(/align="center"/g, "");
      result = result.replace(/align="right"/g, "");
      return result
    }
  }
};
</script>

<style lang="scss" scoped>
@import "ProductDetailsView";
</style>