<template>
  <div class="category-list-container">
    <component
        :is="renderComponent"
        v-bind="$props" />
  </div>
</template>

<script>
import DefaultCategoryList from "./variants/DefaultCategoryList";
import HomepageCategoryList from "./variants/HomepageCategoryList";
import SidebarCategoryList from "./variants/SidebarCategoryList";
import { camelCase, startCase } from 'lodash';

export default {
  name: "CategoryListBase",
  components: {
    DefaultCategoryList,
    HomepageCategoryList,
    SidebarCategoryList
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    items: {
      type: Array,
      required: true
    },
    categoryListItemVariant: {
      type: String,
      default: "default"
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultCategoryList';
      }
      return startCase(camelCase(`${this.variant}CategoryList`)).replace(/ /g, '');
    }
  }
};
</script>
