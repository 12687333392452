// import products from '@/mock/mockProducts.json';
// import categories from '@/mock/mockCategories.json';
import axios from 'axios';

const state = () => {
  return {
    api: 'api.canetoad.nunki.com.au',
    categories: [],
    products: [],
    shoppingCart: [],
    filters: {},
    showCart: false,
    categoryMapping: []
  };
};
function getProtocol() {
  let protocol = 'https://'
  if (location.protocol !== 'https:') {
    protocol = 'http://'
  }
  return protocol
}
function paginateArray(array, pageSize, pageNumber) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}
const actions = {
  async fetchProducts({ commit, state }) {
    try {
      const response = await axios.get(`${getProtocol()}${state.api}/mock/mockProductsLarge.json`, {
        headers: {
          'Authorization': `Bearer: hear-goes-my-auth-token`
        }
      });
      commit('UPDATE_PRODUCTS', response.data);
    } catch (error) {
      alert(error);
    }
  },
  async fetchCategories({ commit, state }, payload) {
    let perPage = 999
    let page = 1

    if (payload?.perPage) {
      perPage = payload.perPage
    }

    if (payload?.page) {
      page = payload.page
    }

    try {
      const response = await axios.get(`${getProtocol()}${state.api}/api/shopCategories/list/?per_page=${perPage}&page=${page}`);
      commit('UPDATE_CATEGORIES', response.data);
    } catch (error) {
      alert(error);
    }
  },
  async importProducts({ state }, payload) {

    try {
      await axios.post(`${getProtocol()}${state.api}/api/shopCategories/saveCategoryMapping`, payload)
    } catch(error) {
      alert(error)
    }
  },
  async getCategoryMapping({ commit, state }) {
    try {
      const response = await axios.get(`${getProtocol()}${state.api}/api/shopCategories/getCategoryMapping`)
      commit("UPDATE_CATEGORY_MAPPING", response.data.data)
    } catch(error) {
      alert(error)
    }
  },
  updateProducts({ commit }, payload) {
    commit('UPDATE_PRODUCTS', payload);
  },
  updateCategories({ commit }, payload) {
    commit('UPDATE_CATEGORIES', payload);
  },
};

const mutations = {
  UPDATE_CATEGORY_MAPPING(state, payload) {
    state.categoryMapping = payload
  },
  UPDATE_FILTERS(state, payload) {
    state.filters = payload;
  },
  UPDATE_PRODUCTS(state, payload) {
    state.products = payload;
  },
  UPDATE_CATEGORIES(state, payload) {
    state.categories = payload;
  },
};

const getters = {

  filteredProducts: (state, getters) => (pagination) => {
    let totalItems = 0
    let totalPages = 0
    let products = state.products;

    if (state.filters.keywordSearch?.length > 2) {
      products = products.filter((product) =>
        product.Name.toLowerCase().includes(state.filters.keywordSearch)
      );

      // if (pagination && pagination.page) {
      //   pagination.page = 1
      // }
    }

    if (state.filters.categoryTitle) {
      const categoryId = getters['getCategoryId'](state.filters.categoryTitle);

      products = state.products.filter(
        (product) =>
          parseInt(product.Categories[0].Category.CategoryID) === categoryId
      );
    }
    if (state.filters.priceRange) {
      products = products.filter(
        (product) =>
          parseFloat(product.DefaultPrice) >=
            state.filters.priceRange.minValue &&
          parseFloat(product.DefaultPrice) <= state.filters.priceRange.maxValue
      );
    }

    if (products.length === 0) {
      products = null
    } else {
      if (pagination && pagination.perPage && pagination.page) {
        totalItems = products.length

        if (totalItems > pagination.perPage) {
          totalPages = Math.floor(totalItems / pagination.perPage)
        }

        products = paginateArray(products, pagination.perPage, pagination.page)
      }
    }

    return {
      products: products,
      totalPages: totalPages,
      totalItems: totalItems
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
