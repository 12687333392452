<template>
  <div class="sidebar-category-list-item-container">
    <router-link
        :to="item.url"
        class="category-link">
      <el-icon name="arrow-right" /> {{ item.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SidebarCategoryListItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-category-list-item-container {


  .category-link {
    display: block;
    width: 100%;
    background: transparent;
    border-radius: var(--border-radius-xs);
    margin: 1px 0;
    padding: var(--padding-xs) var(--padding-m);
    box-sizing: border-box;
    transition: var(--main-transition);
    font-size: var(--font-size-l);
    border-left: 3px solid transparent;

    &:hover {
      background: var(--color-secondary-background);
    }



    &.router-link-exact-active {
      background: var(--color-secondary-background);
      font-weight: 600;
      border-color: var(--color-primary);
      color: black;
    }
  }
}
</style>