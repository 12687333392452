const leftMenuItems = [
  {
    title: 'Dashboard',
    description: 'Back to home',
    url: '/home',
    loggedIn: false
  },
  {
    title: 'Categories',
    description: 'Product Categories',
    url: '/categories/',
    loggedIn: false
  },
  {
    title: 'Product Categorisation',
    description: 'Categorisation for Products',
    url: '/product-categorisation/',
    loggedIn: false
  },
];

export { leftMenuItems };
