<template>
  <router-link
      :to="item.url"
      class="left-navigation-item">
    {{ item.title }}
  </router-link>
</template>

<script>
export default {
  name: 'NavigationItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.left-navigation-item {
  font-family: var(--font-primary);
  font-size: var(--font-size-s);
  background-color: #FFF;
  border-radius: var(--border-radius-xs);
  padding: var(--padding-s) var(--padding-m);
  display: flex;
  align-items: center;
  transition: var(--main-transition);
  letter-spacing: .5px;

  &:hover,
  &.router-link-exact-active {
    background-color: var(--color-secondary-background);
  }
}

@media (max-width: 475px) {
  .left-navigation-item {
    font-size: var(--font-size-m);
    padding: var(--padding-xs) var(--padding-s);
    border: 0;

    &:hover,
    &.router-link-exact-active {
      border: 0;
    }
  }
}
</style>
