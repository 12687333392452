<template>
  <div class="range-container">
    <el-slider
        v-model="value"
        range
        :min="min"
        :max="max"
        :vertical="vertical"
        :format-tooltip="formatToolTip"
        :step="step"
        show-stops />

    <template v-if="showInput">
      <div class="input-container">
        <div class="input-group">
          <label>From</label>
          <el-input
              v-model="minValue"
              class="range-input"
              type="number" />
        </div>
        <div class="input-group">
          <label>To</label>
          <el-input
              v-model="maxValue"
              class="range-input"
              type="number" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Range",
  props: {
    type: {
      type: String,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    showStops: {
      type: Boolean,
      default: true
    },
    step: {
      type: Number,
      default: null
    },
    marks: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    formatToolTip: {
      type: Function,
      default: null
    },
    showInput: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Array,
      default: ()=> [0,0]
    }
  },
  data() {
    return {
      value: this.defaultValue
    }
  },
  computed: {
    filterValues() {
      return {
        minValue: this.minValue,
        maxValue: this.maxValue
      }
    },
    minValue: {
      get() {
        if (this.value.length > 0) {
          return this.value[0]
        }
        return 0
      },
      set(val) {
       this.value = [val, this.value[1]]
      }
    },
    maxValue: {
      get() {
        if (this.value.length > 1) {
          return this.value[1]
        }
        return 0
      },
      set(val) {
        this.value = [this.value[0], val]
      }
    },
  },
  watch: {
    filterValues: {
      handler(val) {
        this.$emit('filterChanges', val)
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.range-container {
  padding: 0 var(--padding-l);

  .input-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .input-group {
      flex: 1;

      label {
        font-size: var(--font-size-s);
        margin-left: var(--margin-s);
        color: var(--color-default-grey);
      }

    }

    ::v-deep .range-input {
      input {
        flex: 1;
        padding: var(--padding-s);
        border-radius: var(--border-radius-xl);
        border-color: var(--color-secondary-background);
      }
    }
  }

}
</style>