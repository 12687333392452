<template>
  <div class="shopping-cart-total-container">
    <div class="subtotal-container">
      <div class="title">
        <h5>Subtotal</h5>
      </div>
      <div class="price-container">
        <ProductPrice
            :rrp-price="Subtotal"
            :mark-discount="false" />
      </div>
    </div>

    <div
        v-if="Discount"
        class="discount-container">
      <div class="title">
        <h5>Discount (your savings)</h5>
      </div>
      <div class="price-container">
        <ProductPrice
            :rrp-price="Discount" />
      </div>
    </div>

    <div class="total-container">
      <div class="title">
        <h4>Total</h4>
      </div>
      <div class="price-container">
        <ProductPrice
            :sell-price="Total" />
      </div>
    </div>

    <div class="checkout-container">
      <button class="checkout">
        Checkout
      </button>
    </div>
  </div>
</template>

<script>
import ProductPrice from "@/Modules/Shop/components/ProductPrice/ProductPrice";
export default {
  name: "ShoppingCartTotal",
  components: { ProductPrice },
  props: {
    shoppingCartItems: {
      type: Array,
      required: true
    }
  },
  computed: {
    Subtotal() {
      let subtotal = 0;

      this.shoppingCartItems.forEach((item) => {
          if (item.productDetails.RRP > 0) {
            subtotal = subtotal + (parseFloat(item.productDetails.RRP) * item.quantity)
          } else {
            subtotal = subtotal + (parseFloat(item.productDetails.DefaultPrice) * item.quantity)
          }
        }
      )
      if (subtotal <= 0) {
        subtotal = this.Total
      }
      return subtotal
    },
    Total() {
      let total = 0;

      this.shoppingCartItems.forEach((item) => {
          total = total + (parseFloat(item.productDetails.DefaultPrice) * item.quantity)
        }
      )

      return total
    },
    Discount() {
      const discount = this.Subtotal - this.Total

      if (discount > 0) {
        return discount
      }
      return false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "ShoppingCartTotal";
</style>