<template>
  <div class="default-category-list-item-container">
    {{ item.title }}
  </div>
</template>

<script>
export default {
  name: "DefaultCategoryListItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.default-category-list-item-container {
  width: 100%;
}
</style>