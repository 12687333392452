<template>
  <div class="content-view">
    <div class="inner-content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultView",
};
</script>

<style lang="scss" scoped>
.content-view {
  display: flex;
  transition: var(--main-transition);

  .inner-content {
    flex: 1;
  }
;
}

</style>