<template>
  <div class="categories-view-container">
    <header>
      <h1>Category List</h1>
    </header>

    <ul class="main-categories">
      <li
          v-for="(mainCategory, index) in SortedCategories"
          :key="`main-category-${index}`">
        {{ mainCategory.title }}
        <ul
            v-if="mainCategory.children"
            class="sub-categories">
          <li
              v-for="(subCategory, subIndex) in mainCategory.children"
              :key="`sub-category-${subIndex}`"
              :class="{ block: subCategory.children.length }">
            {{ subCategory.title }}
            <ul
                v-if="subCategory.children"
                class="sub-sub-categories">
              <li
                  v-for="(subSubCategory, subSubIndex) in subCategory.children"
                  :key="`sub-category-${subSubIndex}`">
                {{ subSubCategory.title }}
              </li>
            </ul>
            <el-button
                class="add-category-button"
                size="mini">
              Add Sub Category
            </el-button>
          </li>
        </ul>
        <el-button
            class="add-category-button"
            size="mini">
          Add Category
        </el-button>
      </li>
    </ul>
    <el-button
        class="add-category-button"
        size="mini">
      Add Main Category
    </el-button>
  </div>
</template>

<script>
export default {
  name: "CategoriesView",
  computed: {
    Categories() {
      if (this.$store.state.Admin.categories?.data?.data) {
        return this.$store.state.Admin.categories.data.data
      }
      return []
    },
    SortedCategories() {

      const { Categories } = this
      let mainCategories = Categories.filter(category => category.parentId === "0")
      mainCategories = mainCategories.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))


      mainCategories.forEach(mainCategory => {
        let subCategories = Categories.filter(subCategory => subCategory.parentId === mainCategory.id)
        subCategories = subCategories.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))

        mainCategory.children = subCategories.map(subCategory => {
          subCategory.children = Categories.filter(subSubCategory => subSubCategory.parentId === subCategory.id)
          return subCategory
        })
      })

      return mainCategories
    }
  },
  created() {
    this.$store.dispatch("Admin/fetchCategories")
  }
};
</script>

<style lang="scss" scoped>
@import "CategoriesView";

</style>