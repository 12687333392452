<template>
  <div class="shop-view">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "ShopView"
};
</script>

<style scoped>

</style>