<template>
  <div class="checkbox-list-filter" />
</template>
<script>
export default {
  name: "CheckboxList",
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>

</style>