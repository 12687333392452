import ProductDetailsView from '@/Modules/Shop/views/ProductDetailsView';
import CategoryListView from "@/Modules/Shop/views/CategoryListView";
const routes = [
  {
    path: '/categories',
    name: 'categories-view',
    component: CategoryListView,
  },
  {
    path: '/product/:productTitle',
    name: 'product-details-view',
    component: ProductDetailsView
  }
];

export default routes;
