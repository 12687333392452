<template>
  <div class="product-search-call-to-action-container">
    <div class="content-container">
      <h1 v-if="title">
        {{ title }}
      </h1>
      <h2 v-if="subtitle">
        {{ subtitle }}
      </h2>
      <div class="search-container">
        <el-autocomplete
            v-model="keyword"
            class="search-input"
            popper-class="my-autocomplete"
            :fetch-suggestions="querySearch"
            placeholder="Search products ..."
            @select="handleSelect">
          <i
              slot="prefix"
              class="el-icon-search el-input__icon"
              @click="handleIconClick" />
          <template slot-scope="{ item }">
            <div class="value">
              {{ item.value }}
            </div>
          </template>
        </el-autocomplete>
      </div>
    </div>
    <div class="shade-divider hidden-xs-only">
      <BackgroundShade fill="#F9D3BD" />
    </div>
    <div class="image-container" />
  </div>
</template>

<script>
import BackgroundShade from "@/Modules/Core/components/CallToAction/components/BackgroundShade";
export default {
  name: 'ProductSearchCallToAction',
  components: {
    BackgroundShade
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      keyword: '',
      links: []
    };
  },
  mounted() {
    this.links = this.loadAll();
  },
  methods: {
    querySearch(queryString, cb) {
      const links = this.links;
      const results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestion objects
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    loadAll() {
      return [
        {
          value: 'Bullbar for Isuzu D-Max',
          link: 'https://github.com/vuejs/vue'
        },
        {
          value: 'Tub Rack for Jeep Gladiator',
          link: 'https://github.com/ElemeFE/element'
        },
        {
          value: 'Rotopax Jerry Can',
          link: 'https://github.com/ElemeFE/cooking'
        },
        {
          value: 'Rearbar for Isuzu D-Max',
          link: 'https://github.com/ElemeFE/mint-ui'
        },
        {
          value: 'Rock Sliders for Isuzu D-Max',
          link: 'https://github.com/vuejs/vuex'
        }
      ];
    },
    handleSelect(item) {
      return item
    },
    handleIconClick(ev) {
      return ev
    }
  }
};
</script>

<style lang="scss" scoped>
@import "ProductSearchCallToAction";
</style>
