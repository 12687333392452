<template>
  <div class="vertical-filter-panel">
    <div
        v-for="(filter, index) in filters"
        :key="`filter-${index}`">
      <h3 v-if="filter.title">
        {{ filter.title }}
      </h3>
      <CoreFilterBase
          :variant="filter.variant"
          :type="filter.type"
          :filter-props="filter.props"
          @filterChanges="updateFilters" />
    </div>
  </div>
</template> 

  <script>
export default {
  name: "VerticalFilterPanel",
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateFilters(val){
      this.$emit("filterChanges", val)
    }
  }
};
</script>

<style lang="scss" scoped>
.vertical-filter-panel {
  background: var(--color-panel-background-bright);
  padding: var(--padding-m);
  border-radius: var(--border-radius-s);

  h3 {
    font-weight: 600;
    font-size: var(--font-size-l);
    margin-left: var(--margin-s);
  }
}
</style>