<template>
  <div class="categories-view-container">
    <header>
      <h1>Category List</h1>
    </header>

    <ul class="main-categories">
      <li
          v-for="(mainCategory, index) in Categories"
          :key="`main-category-${index}`">
        {{ mainCategory.title }}
      </li>
    </ul>


    <el-select v-model="perPage">
      <el-option
          v-for="index in pageSelector"
          :key="index"
          value="index">
        {{ index }}
      </el-option>
    </el-select>

    <div class="page-totals">
      <strong>{{ CategoryMeta.count }}</strong> categories out of <strong>{{ CategoryMeta.total }}</strong> in total
    </div>

    PageCount: {{ PageCount }}
    <div class="paginator">
      <ul class="page-count">
        <li
            v-for="page in PageCount"
            :key="page">
          <el-button
              size="small"
              :disabled="currentPage === page"
              @click="currentPage = page">
            {{ page }}
          </el-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesView",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      pageSelector: [5,10,15,30,40,50]
    }
  },
  computed: {
    PageCount() {
      return Math.ceil(this.CategoryMeta.total / this.perPage)
    },
    CategoryMeta() {
      if (this.$store.state.Shop.categories?.data?.meta) {
        return this.$store.state.Shop.categories.data.meta
      }
      return {
        count: 0,
        total: 0
      }
    },
    Categories() {
      if (this.$store.state.Shop.categories?.data?.data) {
        return this.$store.state.Shop.categories.data.data
      }
      return []
    },
  },
  watch: {
    perPage() {
      this.fetchCategories()
    },
    currentPage() {
      this.fetchCategories()
    }
  },
  created() {
    this.fetchCategories()
  },
  methods: {
    fetchCategories() {
      this.$store.dispatch("Shop/fetchBackendCategories", { perPage: this.perPage, page: this.currentPage})
    }
  }
};
</script>

<style lang="scss" scoped>
.categories-view-container {
  padding: var(--padding-xl);
  min-width: 100%;

  header {
    h1 {
      color: #2f2e49;
      font-size: var(--font-size-xxxl);
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.main-categories {
    list-style: none;
    padding: var(--padding-m) var(--padding-l);
    border-radius: var(--border-radius-m);
    color: #2f2e49;

    li {
      padding: var(--padding-s) var(--padding-m);
      border-radius: var(--border-radius-xs);
      font-size: var(--font-size-m);
      background: #FFF;
      border: 1px solid var(--color-light-grey);
      font-weight: 500;
      margin: var(--margin-s) 0;
      position: relative;
      padding-left: var(--padding-l);
    }
  }

  .page-count {
    display: flex;
    gap: 20px;
  }

}

</style>