<template>
  <div class="default-category-list">
    <template v-if="items.length > 0">
      <CategoryListItemBase
          v-for="(item, index) in items"
          :key="`category-list-item-${index}`"
          :variant="categoryListItemVariant"
          :item="item" />
    </template>
  </div>
</template>

<script>
import CategoryListItemBase from "@/Modules/Shop/components/CategoryListItem/CategoryListItemBase";
export default {
  name: "DefaultCategoryList",
  components: { CategoryListItemBase },
  props: {
    items: {
      type: Array,
      required: true
    },
    categoryListItemVariant: {
      type: String,
      default: "default"
    }
  }
};
</script>

<style lang="scss" scoped>
.default-category-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
</style>