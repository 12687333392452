<template>
  <div class="left-navigation-base-container">
    <template v-if="items">
      <ul class="left-navigation">
        <li
            v-for="(item, index) in items"
            :key="`nav-item-${index}`">
          <TopNavigationItem
              :item="item" />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import TopNavigationItem from "./LeftNavigationItem";
export default {
  name: 'TopNavigation',
  components: {
    TopNavigationItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "LeftNavigation";
</style>
