// import products from '@/mock/mockProducts.json';
// import categories from '@/mock/mockCategories.json';
import axios from 'axios';

const state = () => {
  return {
    api: 'api.canetoad.nunki.com.au',
    categories: [],
    products: [],
    shoppingCart: [],
    filters: {},
    showCart: false,
  };
};
function getProtocol() {
  let protocol = 'https://'
  if (location.protocol !== 'https:') {
    protocol = 'http://'
  }
  return protocol
}
function paginateArray(array, pageSize, pageNumber) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}
const actions = {
  async fetchProducts({ commit, state }) {
    try {
      const response = await axios.get(`${getProtocol()}${state.api}/mock/mockProductsLarge.json`, {
        headers: {
          'Authorization': `Bearer: hear-goes-my-auth-token`
        }
      });
      commit('UPDATE_PRODUCTS', response.data);
    } catch (error) {
      alert(error);
    }
  },
  async fetchCategories({ commit, state }) {
    try {
      const response = await axios.get(`${getProtocol()}${state.api}/mock/mockCategories.json`);
      commit('UPDATE_CATEGORIES', response.data);
    } catch (error) {
      alert(error);
    }
  },
  async fetchBackendCategories({ commit, state }, payload) {
    let perPage = 10
    let page = 1

    if (payload.perPage) {
      perPage = payload.perPage
    }

    if (payload.page) {
      page = payload.page
    }

    try {
      const response = await axios.get(`${getProtocol()}${state.api}/api/shopCategories/list/?per_page=${perPage}&page=${page}`);
      commit('UPDATE_CATEGORIES', response.data);
    } catch (error) {
      alert(error);
    }
  },
  updateProducts({ commit }, payload) {
    commit('UPDATE_PRODUCTS', payload);
  },
  updateCategories({ commit }, payload) {
    commit('UPDATE_CATEGORIES', payload);
  },
  updateFilters({ state, commit }, payload) {
    const newFilters = payload;
    const currentFilters = JSON.parse(JSON.stringify(state.filters));

    currentFilters[newFilters.type] = newFilters.values;

    commit('UPDATE_FILTERS', currentFilters);
  },
  updateShoppingCartQuantity({ commit, state }, payload) {
    const mutatedCartItems = JSON.parse(JSON.stringify(state.shoppingCart));

    mutatedCartItems.find((item) => {
      if (parseInt(item.id) === parseInt(payload.itemId)) {
        if (payload.quantity !== 0) {
          item.quantity = payload.quantity;
        }
      }
    });

    commit('UPDATE_SHOPPING_CART', mutatedCartItems);
  },
  addToShoppingCart({ commit, state }, payload) {
    const mutatedCartItems = JSON.parse(JSON.stringify(state.shoppingCart));
    const productId = payload.id;
    const productQuantity = payload.quantity;

    let itemFound = false;

    mutatedCartItems.forEach((item) => {
      if (item.id === productId) {
        item.quantity = item.quantity + productQuantity;
        itemFound = true;
      }
    });

    if (!itemFound) {
      mutatedCartItems.push(payload);
    }

    commit('UPDATE_SHOPPING_CART', mutatedCartItems);
  },
  loadShoppingCartFromLocalStorage({ commit }) {
    if (localStorage.getItem('shoppingCart')) {
      const shoppingCart = JSON.parse(localStorage.getItem('shoppingCart'));
      commit('UPDATE_SHOPPING_CART', shoppingCart);
    }
  },
  updateShowCart({ commit }, payload) {
    commit('UPDATE_SHOW_CART', payload);
  },
  removeItemFromCart({ commit, state }, payload) {
    const mutatedCartItems = JSON.parse(JSON.stringify(state.shoppingCart));
    mutatedCartItems.splice(payload, 1);

    commit('UPDATE_SHOPPING_CART', mutatedCartItems);
  }
};

const mutations = {
  UPDATE_FILTERS(state, payload) {
    state.filters = payload;
  },
  UPDATE_PRODUCTS(state, payload) {
    state.products = payload;
  },
  UPDATE_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  UPDATE_SHOPPING_CART(state, payload) {
    state.shoppingCart = payload;
    localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart));
  },
  UPDATE_SHOW_CART(state, payload) {
    state.showCart = payload;
  }
};

const getters = {
  getProductIdFromSlug: () => (productSlug) => {
    let productId = null

    if (productSlug) {
      const match = productSlug.match(/(?:-)(pid\d+)$/)

      if (match.length > 1) {
        if (match[1].includes('pid')) {
          productId = parseInt(match[1].replace('pid', ""))
        }
      }
    }
    return productId
  },
  getProductFromId: (state) => (productId) => {
    return state.products.find(product => parseInt(product.ID) === productId)
  },
  getCategoryId: (state) => (categoryTitle) => {
    const category = state.categories.find(
      (category) => category.url.replace('/shop/', '') === categoryTitle
    );

    if (category?.id) {
      return category.id;
    }
    return 1;
  },
  shoppingCartItems: (state) => {
    let mutatedItems = []

    mutatedItems = state.shoppingCart.filter((item) => {
      item.productDetails = state.products.find(product => parseInt(product.ID) === item.id)
      if (item.productDetails) {
        return item
      }
    })

    return mutatedItems
  },
  filteredProducts: (state, getters) => (pagination) => {
    let totalItems = 0
    let totalPages = 0
    let products = state.products;

    if (state.filters.keywordSearch?.length > 2) {
      products = products.filter((product) =>
        product.Name.toLowerCase().includes(state.filters.keywordSearch)
      );

      // if (pagination && pagination.page) {
      //   pagination.page = 1
      // }
    }

    if (state.filters.categoryTitle) {
      const categoryId = getters['getCategoryId'](state.filters.categoryTitle);

      products = state.products.filter(
        (product) =>
          parseInt(product.Categories[0].Category.CategoryID) === categoryId
      );
    }
    if (state.filters.priceRange) {
      products = products.filter(
        (product) =>
          parseFloat(product.DefaultPrice) >=
            state.filters.priceRange.minValue &&
          parseFloat(product.DefaultPrice) <= state.filters.priceRange.maxValue
      );
    }

    if (products.length === 0) {
      products = null
    } else {
      if (pagination && pagination.perPage && pagination.page) {
        totalItems = products.length

        if (totalItems > pagination.perPage) {
          totalPages = Math.floor(totalItems / pagination.perPage)
        }

        products = paginateArray(products, pagination.perPage, pagination.page)
      }
    }

    return {
      products: products,
      totalPages: totalPages,
      totalItems: totalItems
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
