<template>
  <div class="default-category-list-item-container">
    <div class="image-container">
      <img
          :src="require(`@/assets/img/${item.image}`)"
          :alt="item.title">
    </div>
    <div class="title-container">
      <h2>{{ item.title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultCategoryListItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.default-category-list-item-container {
  background: white;
  border-radius: var(--border-radius-m);
  display: flex;
  align-items: center;
  width: calc(50% - 20px);
  padding: var(--padding-m);
  box-sizing: border-box;
  transition: var(--main-transition);
  cursor: pointer;

  .title-container {
    flex: 1;

    h2 {
      font-size: var(--font-size-l);
      font-weight: 600;
    }
  }
  .image-container {
    flex: 1;

    img {
      width: 100%;


      max-width: 70px;
    }
  }

  &:hover {
    box-shadow: var(--box-shadow-shortest);

    .title-container {
      h2 {
        color: var(--color-primary);
      }
    }
  }
}
</style>