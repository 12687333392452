import CoreNavigationBase from './components/Navigation/NavigationBase';
import CoreCallToActionBase from './components/CallToAction/CallToActionBase';
import CoreFooter from './components/Footer/Footer';
import CoreImageBase from './components/Image/ImageBase';
import CoreFilterBase from './components/Filter/FilterBase';
import CoreDrawer from './components/Drawer/Drawer';
import CorePagination from "./components/Pagination/Pagination"

const index = function (Vue) {
  Vue.component('CoreNavigationBase', CoreNavigationBase);
  Vue.component('CoreCallToActionBase', CoreCallToActionBase);
  Vue.component('CoreFooter', CoreFooter);
  Vue.component('CoreImageBase', CoreImageBase);
  Vue.component('CoreFilterBase', CoreFilterBase);
  Vue.component('CoreDrawer', CoreDrawer);
  Vue.component("CorePagination", CorePagination);
};

export default index;
