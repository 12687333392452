<template>
  <component
      :is="renderComponent"
      v-bind="$props" />
</template>

<script>
import { camelCase, startCase } from 'lodash';
import DefaultCategoryListItem from "./variants/DefaultCategoryListItem";
import SidebarCategoryListItem from "./variants/SidebarCategoryListItem";
import HomepageCategoryListItem from "./variants/HomepageCategoryListItem";

export default {
  name: "CategoryListItemBase",
  components: {
    DefaultCategoryListItem,
    SidebarCategoryListItem,
    HomepageCategoryListItem
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    categoryListItemVariant: {
      type: String,
      default: "default"
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultCategoryListItem';
      }
      return startCase(camelCase(`${this.variant}CategoryListItem`)).replace(/ /g, '');
    }
  }
};
</script>

<style scoped>

</style>