import HomeView from '@/Modules/Home/views/Home';

const HomeModule = {
  path: 'home',
  enabled: true,
  component: HomeView,
  name: 'home-view'
};

export default HomeModule;
